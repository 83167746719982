const validationMessage = document.getElementById("validation-message");
const requiredFields = document.querySelectorAll(".required");
const form = document.querySelector('form');

function form_errors() {
    let errors = '';

    if (required_field_errors() !== '') {
        errors += ' Please fill in required fields';
    }
    return errors;
}

function required_field_errors() {
    let errors = '';
    requiredFields.forEach(field => {
        if (invalid_field(field) && field.offsetWidth > 0 && field.offsetHeight > 0) {
            errors = ' Cannot Be Blank!';
        }
    });
    return errors;
}

function invalid_field(field) {
    let error = null;
    if (field.value === '') {
        error = 'Cannot be Blank';
    }
    if (error) {
        add_error(field, error);
    } else {
        clear_error(field);
    }
    return error;
}

function add_error(field, error) {
    const error_field_id = field.name + '_error';
    field.classList.add("error");
    field.style.backgroundColor = "#f2dede";
    field.style.color = '#8B0000';
    const error_html = `<p id="${error_field_id}" style="color:#8B0000;">${error}</p>`;
    field.insertAdjacentHTML('afterend', error_html);
}

function clear_error(field) {
    const error_field_id = field.name + '_error';
    field.classList.remove("error");
    field.style.backgroundColor = "#ffffff";
    field.style.color = "black";
    const errorElement = document.getElementById(error_field_id);
    if (errorElement) {
        errorElement.style.display = 'none';
    }
}

function submit_form() {
    const errors = form_errors();
    if (errors === '') {
        validationMessage.style.display = 'none';
        form.submit();
    } else {
        validationMessage.textContent = 'There are errors preventing this form from being saved.' + errors;
        validationMessage.style.display = 'block';
    }
}

export {submit_form, clear_error, add_error};