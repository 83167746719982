class removeFields {
    constructor() {
        this.iterateLinks()
    }
    iterateLinks() {

        document.addEventListener('click', e => {
            if (e.target && e.target.classList.contains('remove-fields')) {
                this.handleClick(e.target, e)
            }
        })
    }

    handleClick(link, e) {
        if (!link || !e) return
        e.preventDefault()
        let fieldParent = link.parentNode
        let parentNode = link.parentNode.nodeName === 'TD' ? fieldParent.parentNode : link.parentNode
        let deleteField = fieldParent ? fieldParent.querySelector('input[type="hidden"]') : null
        if (deleteField) {
            deleteField.value = 1
            parentNode.style.display = 'none'
        }
    }
}
window.addEventListener('turbo:load', () => new removeFields())